.App {
  margin-top: 20px;
  margin-bottom: 20px;
}

.primary-card {
  margin-top: 20px;
  background-color: #fff;
  border-color: #428bca;
}

.code {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.primary-card>.primary-card-header {
  color: #fff !important;
  background-color: #428bca !important;
  border-color: #428bca !important;
}